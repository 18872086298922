<script>
import SvgIcon from "@/assets/icon/index.vue";
import tooltip from "@/utils/common/tooltip";
import eventBus from "@/utils/eventBus";
export default {
  name: "WorkFlowOrderReviewTwo",
  components: {SvgIcon},
  data(){
    return{
      consent_state: false,
      star_state: 0,
      review_title: "",
      star_click: 0,
      consent_class: ""
    }
  },
  props: ["review_data","order"],
  computed: {
    star_color() {
      return (id) => {
        return this.star_state >= id ? "#FE8702" : "white"
      }
    }
  },
  watch: {
    review_data(new_data){
      this.star_state = new_data.star
      this.review_title = new_data.message
      this.star_click = new_data.star
    }
  },
  methods: {
    verification(){
      if (!this.consent_state){
        if (this.consent_class !== '')return true;
        this.consent_class = ' animate__animated animate__tada'
        setTimeout(()=>{
          this.consent_class = ""
        },1000)
        return true;
      }
      return false;
    },
    async copy_text(event){
      try {
        if (navigator.clipboard && window.isSecureContext){
          await navigator.clipboard.writeText(this.review_title)
          tooltip("Copy Success",event)
        }else {
          tooltip("Copy failed",event)
        }
        this.post_to()
      } catch (error){
        tooltip("Copy failed",event)
      }
    },
    post_to(){
      if (this.star_state > 3){
        if (this.verification())return;
        window.open(`https://www.${this.order.marketplace}/review/create-review?&asin=${this.order.product_asin}:${this.star_state}`)
      }else {
        eventBus.$emit("contact_path")
        eventBus.$emit("contact_updata",{
          name: this.order.name,
          email: this.order.email,
          message: this.review_title,
        })
      }
    }
  }
}
</script>

<template>
  <div class="work-flow-order-review-one-main">
    <span class="work-flow-order-review-one-title">Step 2 of 3: Share Your Experience With Others</span>
    <div class="work-flow-order-review-one-divider">
      <div></div>
    </div>
    <span style="font-size: 20rem;">It won't take long,just a few seconds.Your opinion will be seen by other customers to help them make a better buying decision.Sincere thanks for your kindness,and come back to this page after review is done.</span>
    <span style="text-align: center;font-size: 30rem;margin-top: 20rem;">Your Comment</span>
    <div class="work-flow-order-review-one-star" @mouseleave="star_state = star_click">
      <div v-for="(item,index) in [1,2,3,4,5]" :style="`color: ${star_color(item)};`" :key="index">
        <svg-icon icon-class="Star" @mouseenter="star_state = item" @click="star_click = item"></svg-icon>
      </div>
    </div>
    <div style="display: flex;justify-content: center;">
      <textarea
          cols="10"
          placeholder="Please enter at least 4 characters."
          v-model="review_title"
          id="work-flow-order-review-one-textarea"
          data-clipboard-text="review_title"
      >
      </textarea>
    </div>
    <div :class="`work-flow-order-review-one-consent${consent_class}`" @click="consent_state = !consent_state">
      <svg-icon :icon-class="`${consent_state?'true':''}`" :style="`background-color: white;color: #5de15d;border-radius: 3rem;padding: 1rem;font-size: 22rem;`"></svg-icon>
      <span style="margin-left: 20rem">I know returning to this page for the final step When Review done.</span>
    </div>
    <div class="work-flow-order-review-one-button">
      <div @click="copy_text">COPY YOUR COMMENT</div>
      <div @click="post_to">POST TO Amazon</div>
    </div>
  </div>
</template>

<style scoped>
.work-flow-order-review-one-main{
  width: 1000rem;
  display: flex;
  flex-direction: column;
  background-color: #8c8c95;
  padding: 30rem;
  border-radius: 15rem;
}
.work-flow-order-review-one-title{
  font-size: 30rem;
}
.work-flow-order-review-one-divider{
  background-color: white;
  height: 3rem;
  margin: 20rem 0;
}
.work-flow-order-review-one-divider > div{
  height: 100%;
  width: 66%;
  background-color: #FE8702;
}
.work-flow-order-review-one-star{
  display: flex;
  font-size: 50rem;
  margin: 20rem 0;
  justify-content: center;
}
.work-flow-order-review-one-star svg{
  cursor: pointer;
}
.work-flow-order-review-one-star > div{
  margin-right: 15rem;
}
.work-flow-order-review-one-main textarea{
  width: 90%;
  height: 100rem;
  background-color: white;
  padding: 5rem;
}
.work-flow-order-review-one-consent{
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-top: 20rem;
  font-size: 20rem;
  user-select: none;
  cursor: pointer;
}
.work-flow-order-review-one-button{
  display: flex;
  justify-content: space-around;
  margin-top: 30rem;
}
.work-flow-order-review-one-button > div{
  padding: 15rem 40rem;
  border-radius: 10rem;
  user-select: none;
  cursor: pointer;
}
.work-flow-order-review-one-button div:nth-child(1){
  background-image: linear-gradient(to bottom,rgb(150,158,167),rgb(117,115,113));
  margin-right: 20rem;
}
.work-flow-order-review-one-button div:nth-child(2){
  background-image: linear-gradient(to bottom,#FCB211,#FE8702);
}
@media (max-width: 600px) or (max-aspect-ratio:1/1) {
  .work-flow-order-review-one-main {
    width: 400rem;
  }
}
</style>