<script>
import SvgIcon from "@/assets/icon/index.vue";
import LoadIcon from "@/common/LoadIcon.vue";
import {order_consult} from "@/api/updata";
import EventBus from "@/utils/eventBus";

export default {
  name: "WorkFlowOrder",
  components: {LoadIcon, SvgIcon},
  data(){
    return {
      Compliance_Judgment: {
        name: {
          state: "",
          value: ""
        },
        email: {
          state: "",
          value: ""
        },
        order: {
          state: "",
          value: ""
        }
      },
      break_state: "",
      load_break_state: false
    }
  },
  methods: {
    input_name(){
      this.Compliance_Judgment.name.state = this.Compliance_Judgment.name.value !== "";
    },
    input_email(){
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      this.Compliance_Judgment.email.state = emailRegex.test(this.Compliance_Judgment.email.value);
    },
    input_order(){
      this.Compliance_Judgment.order.state = this.Compliance_Judgment.order.value !== "";
    },
    async upload_order(){
      if (this.load_break_state === true)return;
      this.input_name()
      this.input_email()
      this.input_order()
      if (this.Compliance_Judgment.name.state && this.Compliance_Judgment.order.state && this.Compliance_Judgment.email.state) {
        this.load_break_state = true
        try {
          const res = await order_consult({
            name: this.Compliance_Judgment.name.value,
            email: this.Compliance_Judgment.email.value,
            order: this.Compliance_Judgment.order.value
          })
          EventBus.$emit("contact_query_id", res.query_id)
          this.$emit("flowOrder",{...res,name:this.Compliance_Judgment.name.value,email:this.Compliance_Judgment.email.value})
        }catch (error){
          console.log(error)
        }
        this.load_break_state = false
      }
    }
  }
}
</script>

<template>
<div class="work-flow-order-main">
  <div class="present-order">
    <span style="color: white;font-size: 40rem;font-weight: bold;">Activate Your Warranty and BUY ONE GET ONE!</span>
    <span style="color: rgb(254,137,4);font-size: 40rem;font-weight: bold;">NO Shipping Charges,NO Hidden Fees,NO Credit Card Required!</span>
    <span style="color: white;font-size: 20rem;">* Conditions Apply: To qualify,participants agree to send us their experience with the previously purchased product that they have been actively using for at least 5 days.</span>
    <hr style="background-color: white">
    <span style="color: rgb(254,137,4);font-size: 20rem;">
      1-Only for customers that bought the product at FULL PRICE through our Official Seller on Amazon.com <br>
      2-Each customer can only claim one FREE GIFT for one product.(Which means no matter how many times you purchase the same product,you can only get one FREE gift).
    </span>
    <hr style="background-color: white">
  </div>
  <div class="form-order">
    <span>Activate Your Warranty and Receive Your FREE Gift Now!</span>
    <span>Offer Limited to Existing Customers</span>
    <div class="form-input">
      <span>Name*</span>
      <div class="form-warnings" v-show="Compliance_Judgment.name.state === false">
        <svg-icon icon-class="bug"></svg-icon>
        <span>Please enter a name</span>
      </div>
      <input
          type="text"
          placeholder="Enter Your Name..."
          v-model="Compliance_Judgment.name.value"
          @blur="input_name"/>
      <span>Email*</span>
      <div class="form-warnings" v-show="Compliance_Judgment.email.state === false">
        <svg-icon icon-class="bug"></svg-icon>
        <span>Please enter a valid email address</span>
      </div>
      <input
          type="text"
          placeholder="Enter Your Email..."
          v-model="Compliance_Judgment.email.value"
          @blur="input_email"/>
      <span>Order ID*</span>
      <div class="form-warnings" v-show="Compliance_Judgment.order.state === false">
        <svg-icon icon-class="bug"></svg-icon>
        <span>Please enter a order ID</span>
      </div>
      <div class="form-error" v-show="break_state === false">
        <div class="form-warnings">
          <svg-icon icon-class="bug"></svg-icon>
          <span>We can’t find your order ID.</span>
        </div>
        <span>If any questions, please contact us.</span>
        <span>xxx@xx.xx</span>
      </div>
      <input
          type="text"
          placeholder="Enter Your Order ID..."
          v-model="Compliance_Judgment.order.value"
          @blur="input_order"/>
      <div class="form-button" @click="upload_order" :style="`cursor: ${load_break_state?'not-allowed':'pointer'}`">
        <LoadIcon style="color: white;" v-show="load_break_state"></LoadIcon>
        <span>Activate Now</span>
      </div>
    </div>
    <div style="display: flex;flex-direction: column;line-height: 40rem;">
      <span style="color: rgb(254,137,4);font-size: 20rem;font-weight: bold;">*How to find your Order ID?</span>
      <span style="color: white;font-size: 20rem;font-weight: bold;">*We don't share your personal info with anyone.Check out our Privacy Policy for more information</span>
    </div>
  </div>
</div>
</template>

<style scoped>
.work-flow-order-main{
  display: flex;
  justify-content: center;
}
.present-order{
  width: 600rem;
  display: flex;
  flex-direction: column;
  margin-right: 100rem;
}
.present-order > *{
  margin-bottom: 40rem;
}
.form-order{
  width: 550rem;
  padding: 40rem;
  box-sizing: border-box;
  background-color: #5b5a67;
  border-radius: 20rem;
  display: flex;
  flex-direction: column;
}
.form-order > *{
  margin-bottom: 30rem;
}
.form-order > span{
  color: white;
  font-size: 30rem;
  font-weight: bold;
}
.form-order span:nth-child(2){
  font-size: 25rem;
}
.form-input{
  display: flex;
  flex-direction: column;
}
.form-input > input{
  height: 50rem;
  background-color: white;
  padding-left: 20rem;
  font-size: 25rem;
}
.form-input > span{
  color: black;
}
.form-warnings{
  color: rgb(255, 145, 145);
}
.form-error{
  display: flex;
  flex-direction: column;
}
.form-error > span{
  margin-left: 25rem;
}
.form-button{
  background-image: linear-gradient(to bottom,#FCB211,#FE8702);
  height: 70rem;
  line-height: 70rem;
  text-align: center;
  user-select: none;
  border-radius: 15rem;
  margin-top: 20rem;
}
@media (max-width: 600px) or (max-aspect-ratio:1/1) {
  .present-order{
    display: none;
  }
  .form-order{
    width: 480rem;
  }
  .form-order > span{
    font-size: 25rem;
  }
  .form-order > span:nth-child(2){
    font-size: 25rem;
  }
}
</style>
